import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import moment from 'moment';
import {withRouter, resolveRoute} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {ampMemberStatusTypes, ampMemberTariffGroups, alfInvoiceStatusTypes, alfInvoiceTypes, alfInvoiceChannelTypes} from '@ace-de/eua-entity-types';
import {Badge, DataRow, Divider, DropDownTrigger, Icon, Panel, PopOver, useStyles, withDropDownProvider} from '@ace-de/ui-components';
import {arrowDownIcon, arrowUpIcon, InteractiveIcon, detailsIcon} from '@ace-de/ui-components/icons';
import * as invoiceSelectors from '../invoiceSelectors';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import config from '../../config';
import routePaths from '../../routePaths';

const InvoiceOverviewBaseDataPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate, activeLocale} = useTranslate();
    const translatePanel = createTranslateShorthand('invoice_overview_base_data_panel');
    const {invoice, members, serviceCase, membersByMembershipNo} = props;
    const {isAdditionalInfoExpanded, setIsAdditionalInfoExpanded} = props;
    const memberId = serviceCase.member.id;
    const member = invoice.channel === alfInvoiceChannelTypes.MIA && serviceCase.member.membershipNo
        ? membersByMembershipNo[serviceCase.member.membershipNo] : (memberId ? members[memberId] : serviceCase.member);
    const hasCustomContactDetailsPhoneNo = invoice.channel === alfInvoiceChannelTypes.MIA
        && serviceCase.member.contactDetails?.phoneNo;
    const hasCustomContactDetailsEmail = invoice.channel === alfInvoiceChannelTypes.MIA
        && serviceCase.member.contactDetails?.email;
    const [accountParty, setAccountParty] = useState(invoice?.accountParty || null);

    // BE pings BC with each GET invoice by ID request - in case BC is down at that moment
    // BE will return data from the database (id and name). We need to update accountParty
    // as soon as another request is triggered (e.g. by opening some modal) which will have
    // a response with all the data that needs to be shown in info popup
    useEffect(() => {
        if (invoice?.accountParty) {
            setAccountParty(invoice.accountParty);
        }
    }, [invoice?.accountParty, setAccountParty]);

    const getPrice = ({price, currency}) => {
        if (typeof price === 'number' && price >= 0) {
            return price.toLocaleString(activeLocale, {
                style: 'currency',
                currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        }
    };

    const formatDimensions = dimension => {
        if (typeof dimension === 'number' && dimension >= 0) {
            return dimension.toLocaleString(activeLocale, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            });
        }
    };

    if (!invoice || !serviceCase) return null;

    const accountPartyAddress = accountParty?.address;

    return (
        <Panel title={translatePanel('panel_title.base_data', {serviceCaseCommissioner: serviceCase.commissioner})}>
            <Divider />
            <div
                className={cx([
                    'global!ace-u-margin--top-24',
                    'global!ace-u-grid',
                ])}
            >
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-column',
                        'global!ace-u-grid-column--span-4',
                    ])}
                >
                    <DataRow label={translatePanel(`data_row_label.${[alfInvoiceTypes.VKR, alfInvoiceTypes.EKG].includes(invoice.type) ? 'debtor' : 'creditor'}`)} qaIdent="invoice-account-party-name">
                        {accountParty?.name || '-'}
                        <DropDownTrigger>
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-full-width',
                                    'global!ace-u-flex--justify-flex-end',
                                    'global!ace-u-margin--right-32',
                                ])}
                            >
                                <Icon
                                    icon={detailsIcon}
                                    className={cx('global!ace-u-margin--top-4')}
                                />
                            </div>
                            <PopOver alignment="end" hasBorder={true}>
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--direction-column',
                                        'global!ace-u-grid-column--span-4',
                                    ])}
                                >
                                    <DataRow
                                        label={translatePanel('data_row_label.account_party_number')}
                                        qaIdent="account-party-id"
                                    >
                                        {accountParty?.id || '-'}
                                    </DataRow>
                                    <DataRow
                                        label={translatePanel('data_row_label.account_party_address')}
                                        qaIdent="account-party-address"
                                    >
                                        {accountPartyAddress ? (
                                            <div>
                                                <p>{accountPartyAddress.street || '-'}</p>
                                                <p>{`${accountPartyAddress.postCode || '-'} ${accountPartyAddress.city || '-'}`}</p>
                                                <p>{accountPartyAddress.country || '-'}</p>
                                            </div>
                                        ) : '-'}
                                    </DataRow>
                                    <DataRow
                                        label={translatePanel('data_row_label.account_party_iban')}
                                        qaIdent="account-party-iban"
                                    >
                                        {accountParty?.bankAccount?.iban || '-'}
                                    </DataRow>
                                    <DataRow
                                        label={translatePanel('data_row_label.account_party_bank_name')}
                                        qaIdent="account-party-bank"
                                    >
                                        {accountParty?.bankAccount?.name || '-'}
                                    </DataRow>
                                    <DataRow
                                        label={translatePanel('data_row_label.account_party_swift_code')}
                                        qaIdent="account-party-bic"
                                    >
                                        {accountParty?.bankAccount?.swiftCode || '-'}
                                    </DataRow>
                                </div>
                            </PopOver>
                        </DropDownTrigger>
                    </DataRow>
                    <DataRow label={translatePanel('data_row_label.fixed_price')} qaIdent="invoice-fixed-price">
                        {invoice.fixedPrice
                            ? getPrice({price: invoice.fixedPrice, currency: config.CURRENCY})
                            : translatePanel('data_row_content.no')}
                    </DataRow>
                    <DataRow label={translatePanel('data_row_label.receipt_date')} qaIdent="invoice-receipt-date">
                        {invoice.receiptDate
                            ? moment(invoice.receiptDate).format('DD.MM.YYYY')
                            : '-'
                        }
                    </DataRow>
                </div>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-column',
                        'global!ace-u-grid-column--span-4',
                    ])}
                >
                    <DataRow label={translatePanel('data_row_label.damage_date')} qaIdent="service-case-damage-date">
                        {serviceCase?.damage?.date
                            ? moment(serviceCase.damage.date).format('DD.MM.YYYY')
                            : (invoice.channel === alfInvoiceChannelTypes.MIA
                                && invoice.lines?.[0]?.serviceStartDateTime ? moment(invoice.lines?.[0]?.serviceStartDateTime).format('DD.MM.YYYY') : '-')
                        }
                    </DataRow>
                    <DataRow label={translatePanel('data_row_label.channel')} qaIdent="invoice-channel">
                        {invoice.channel || '-'}
                    </DataRow>
                    <DataRow label={translatePanel('data_row_label.booking_date')} qaIdent="invoice-booking-date">
                        {invoice.bookingDate
                            ? moment(invoice.bookingDate).format('DD.MM.YYYY')
                            : '-'
                        }
                    </DataRow>
                </div>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-column',
                        'global!ace-u-grid-column--span-4',
                    ])}
                >
                    <DataRow
                        label={translatePanel('data_row_label.damage_type')}
                        qaIdent="service-case-damage-description"
                    >
                        {serviceCase.damage?.description || '-'}
                    </DataRow>
                    <DataRow label={translatePanel('data_row_label.tariff_status')} qaIdent="member-status-and-tariff-group">
                        {member?.tariffDetails?.currentTariff
                            ? translate(`global.member_tariff_group.${snakeCase(ampMemberTariffGroups[member.tariffDetails.tariffGroup])}`)
                            : '-'}
                        {member?.status && member.status !== ampMemberStatusTypes.EMPTY && (
                            <Badge status={member.status.toLowerCase()} className={cx('global!ace-u-margin--left-8')}>
                                {translate(`global.member_status.${snakeCase(ampMemberStatusTypes[member.status])}`)}
                            </Badge>
                        )}
                    </DataRow>
                    <DataRow label={translatePanel('data_row_label.receipt_id')} qaIdent="invoice-receipt-id">
                        {invoice.status === alfInvoiceStatusTypes.PAID
                            || invoice.status === alfInvoiceStatusTypes.BOOKED
                            ? invoice.receiptId
                            : '-'}
                    </DataRow>
                </div>
            </div>
            <div
                className={cx([
                    'global!ace-u-width--full',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-flex-end',
                ])}
            >
                <InteractiveIcon
                    name="expend-section-arrow"
                    icon={!isAdditionalInfoExpanded ? arrowDownIcon : arrowUpIcon}
                    onClick={() => setIsAdditionalInfoExpanded(!isAdditionalInfoExpanded)}
                    className={cx('global!ace-u-margin--top-40')}
                />
            </div>
            {isAdditionalInfoExpanded && (
                <div className={cx('global!ace-u-grid')}>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--direction-column',
                            'global!ace-u-grid-column--span-4',
                        ])}
                    >
                        <p
                            className={cx([
                                'global!ace-u-margin--bottom-24',
                                'global!ace-u-typography--variant-h3',
                            ])}
                        >
                            {translatePanel('section_title.member')}
                        </p>
                        <DataRow label={translatePanel('data_row_label.name')} qaIdent="member">
                            <div className={cx('global!ace-u-full-width')}>
                                {member?.membershipNo ? (
                                    <a
                                        href={resolveRoute(routePaths.MEMBER,
                                            {membershipNo: member.membershipNo}).pathname
                                        }
                                        className={cx([
                                            'global!ace-u-typography--variant-body-bold',
                                            'global!ace-u-typography--color-highlighted',
                                            'global!ace-u-typography--variant-body',
                                            'global!ace-u-cursor--pointer',
                                        ])}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {memberId ? `${member?.personalDetails.firstName} ${member?.personalDetails.surname}` : member?.personalDetails?.name || '-'}
                                    </a>
                                ) : '-'}
                            </div>
                            <DropDownTrigger>
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-full-width',
                                        'global!ace-u-flex--justify-flex-end',
                                        'global!ace-u-margin--right-32',
                                    ])}
                                >
                                    <Icon
                                        icon={detailsIcon}
                                        className={cx('global!ace-u-margin--top-4')}
                                    />
                                </div>
                                <PopOver alignment="end" hasBorder={true}>
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--direction-column',
                                            'global!ace-u-grid-column--span-4',
                                        ])}
                                    >
                                        <DataRow
                                            label={translatePanel('data_row_label.phone_no')}
                                            qaIdent="member-phone-number"
                                        >
                                            {hasCustomContactDetailsPhoneNo || member?.contactDetails?.phoneNo ? (
                                                <a
                                                    href={`tel:${member.contactDetails.phoneNo}`}
                                                    className={cx([
                                                        'global!ace-u-typography--variant-body-bold',
                                                        'global!ace-u-typography--color-highlighted',
                                                    ])}
                                                >
                                                    {hasCustomContactDetailsPhoneNo
                                                        ? serviceCase.member.contactDetails.phoneNo
                                                        : member.contactDetails.phoneNo}
                                                </a>
                                            ) : '-'}
                                        </DataRow>
                                        <DataRow label={translatePanel('data_row_label.email')} qaIdent="member-email">
                                            {hasCustomContactDetailsEmail || member?.contactDetails?.email ? (
                                                <a
                                                    href={`mailto:${member.contactDetails.email}`}
                                                    className={cx([
                                                        'global!ace-u-typography--variant-body-bold',
                                                        'global!ace-u-typography--color-highlighted',
                                                    ])}
                                                >
                                                    {hasCustomContactDetailsEmail
                                                        ? serviceCase.member.contactDetails.email
                                                        : member.contactDetails.email}
                                                </a>
                                            ) : '-'}
                                        </DataRow>
                                        <DataRow
                                            label={translatePanel('data_row_label.address')}
                                            qaIdent="member-address"
                                        >
                                            {member?.personalDetails?.address ? (
                                                <div>
                                                    <p>{member.personalDetails.address.street}</p>
                                                    <p>{`${member.personalDetails.address.postCode} ${member.personalDetails.address.city}`}</p>
                                                </div>
                                            ) : '-'}
                                        </DataRow>
                                    </div>
                                </PopOver>
                            </DropDownTrigger>
                        </DataRow>
                        <DataRow label={translatePanel('data_row_label.member_id')} qaIdent="member-member_id">
                            {member?.membershipNo || '-'}
                        </DataRow>
                        <DataRow label={translatePanel('data_row_label.entry_date')} qaIdent="member-membership">
                            {member?.beginMembership ? moment(member?.beginMembership).format('DD.MM.YYYY') : '-'}
                            {member?.endMembership
                                ? moment(member.endMembership).isSameOrBefore(moment())
                                    ? ` (${translatePanel('data_row_content.membership_end', {endMembership: moment(member.endMembership).format('DD.MM.YYYY')})})`
                                    : ''
                                : ''}
                        </DataRow>
                        <DataRow label={translatePanel('data_row_label.dunning_level')} qaIdent="member-dunning-level">
                            {member?.dunningLevel || '-'}
                        </DataRow>
                        <DataRow label={translatePanel('data_row_label.balance')} qaIdent="member-balance-member-fee">
                            <span
                                className={cx(member?.tariffDetails?.balanceMemberFee > 0 ? [
                                    'global!ace-u-typography--color-warning',
                                    'global!ace-u-typography--variant-body-bold',
                                ] : [])}
                            >
                                {member?.tariffDetails?.balanceMemberFee >= 0
                                    ? getPrice({
                                        price: member.tariffDetails.balanceMemberFee,
                                        currency: config.CURRENCY,
                                    })
                                    : '-'}
                            </span>
                        </DataRow>
                        <DataRow
                            label={translatePanel('data_row_label.cases_last_year')}
                            qaIdent="member-service-case-count"
                        >
                            {member?.serviceCasesCount || '-'}
                        </DataRow>
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--direction-column',
                            'global!ace-u-grid-column--span-4',
                        ])}
                    >
                        <p
                            className={cx([
                                'global!ace-u-margin--bottom-24',
                                'global!ace-u-typography--variant-h3',
                            ])}
                        >
                            {translatePanel('section_title.vehicle')}
                        </p>
                        <DataRow
                            label={translatePanel('data_row_label.license_plate')}
                            qaIdent="service-case-vehicle-license-plate"
                        >
                            {serviceCase.vehicle?.licensePlateNumber || '-'}
                        </DataRow>
                        <DataRow
                            label={translatePanel('data_row_label.type')}
                            qaIdent="service-case-vehicle-type"
                        >
                            {serviceCase.vehicle?.type
                                ? translate(`global.vehicle_type.${serviceCase.vehicle.type.toLowerCase()}`)
                                : '-'}
                        </DataRow>
                        <DataRow
                            label={translatePanel('data_row_label.manufacturer_model')}
                            qaIdent="service-case-vehicle-manufacturer-and-model"
                        >
                            <div className={cx('global!ace-u-full-width')}>
                                {serviceCase.vehicle?.manufacturer || serviceCase.vehicle?.model
                                    ? `${serviceCase.vehicle?.manufacturer} ${serviceCase.vehicle?.model}`
                                    : '-'}
                            </div>
                            <DropDownTrigger>
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-full-width',
                                        'global!ace-u-flex--justify-flex-end',
                                        'global!ace-u-margin--right-32',
                                    ])}
                                >
                                    <Icon
                                        icon={detailsIcon}
                                        className={cx('global!ace-u-margin--top-4')}
                                    />
                                </div>
                                <PopOver alignment="end" hasBorder={true}>
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--direction-column',
                                            'global!ace-u-grid-column--span-4',
                                        ])}
                                    >
                                        <DataRow
                                            label={translatePanel('data_row_label.manufacturer')}
                                            qaIdent="service-case-vehicle-manufacturer"
                                        >
                                            {serviceCase.vehicle?.manufacturer || '-'}
                                        </DataRow>
                                        <DataRow
                                            label={translatePanel('data_row_label.model')}
                                            qaIdent="service-case-vehicle-model"
                                        >
                                            {serviceCase.vehicle?.model || '-'}
                                        </DataRow>
                                        <DataRow
                                            label={translatePanel('data_row_label.weight')}
                                            qaIdent="service-case-vehicle-weight"
                                        >
                                            {serviceCase.vehicle?.weight
                                                ? formatDimensions(serviceCase.vehicle.weight)
                                                : '-'
                                            }
                                        </DataRow>
                                        <DataRow
                                            label={translatePanel('data_row_label.build_year')}
                                            qaIdent="service-case-vehicle-build-year"
                                        >
                                            {serviceCase.vehicle?.buildYear || '-'}
                                        </DataRow>
                                        <DataRow
                                            label={translatePanel('data_row_label.dimensions')}
                                            qaIdent="service-case-vehicle-dimensions"
                                        >
                                            {`${serviceCase.vehicle?.length
                                                ? formatDimensions(serviceCase.vehicle.length)
                                                : '-'} x
                                                ${serviceCase.vehicle?.width
                                                ? formatDimensions(serviceCase.vehicle.width)
                                                : '-'} x
                                                ${serviceCase.vehicle?.height
                                                    ? formatDimensions(serviceCase.vehicle.height)
                                                    : '-'}`
                                            }
                                        </DataRow>
                                        <DataRow
                                            label={translatePanel('data_row_label.other')}
                                            qaIdent="service-case-vehicle-special-features"
                                        >
                                            <div>
                                                <p>
                                                    {Array.isArray(serviceCase.vehicle?.specialFeatures)
                                                        ? serviceCase.vehicle.specialFeatures.map(specialFeature => {
                                                            return translate(`global.vehicle_special_feature.${specialFeature.toLowerCase()}`);
                                                        }).join(', ')
                                                        : translate(`global.vehicle_special_feature.${serviceCase.vehicle?.specialFeatures?.toLowerCase()}`) || '-'}
                                                </p>
                                                <p>{serviceCase.damage?.vehicleDamageNotes || '-'}</p>
                                            </div>
                                        </DataRow>
                                    </div>
                                </PopOver>
                            </DropDownTrigger>
                        </DataRow>
                        <DataRow
                            label={translatePanel('data_row_label.driver')}
                            qaIdent="service-case-vehicle-driver"
                        >
                            {serviceCase.vehicle?.driver?.isIdenticalToMember
                                ? translatePanel('data_row_content.member')
                                : serviceCase.vehicle?.driver?.memberRelationship
                                    ? translate(`global.member_relationship_type.${serviceCase.vehicle.driver.memberRelationship.toLowerCase()}`)
                                    : '-'}
                            <DropDownTrigger>
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-full-width',
                                        'global!ace-u-flex--justify-flex-end',
                                        'global!ace-u-margin--right-32',
                                    ])}
                                >
                                    <Icon
                                        icon={detailsIcon}
                                        className={cx('global!ace-u-margin--top-4')}
                                    />
                                </div>
                                <PopOver alignment="end" hasBorder={true}>
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--direction-column',
                                            'global!ace-u-grid-column--span-4',
                                        ])}
                                    >
                                        <DataRow
                                            label={translatePanel('data_row_label.type')}
                                            qaIdent="service-case-vehicle-driver-member-relation"
                                        >
                                            {serviceCase.vehicle?.driver?.isIdenticalToMember
                                                ? translatePanel('data_row_content.member')
                                                : serviceCase.vehicle?.driver?.memberRelationship
                                                    ? translate(`global.member_relationship_type.${serviceCase.vehicle.driver.memberRelationship.toLowerCase()}`)
                                                    : '-'}
                                        </DataRow>
                                        <DataRow
                                            label={translatePanel('data_row_label.name')}
                                            qaIdent="service-case-vehicle-driver-name"
                                        >
                                            {serviceCase.vehicle?.driver?.name || '-'}
                                        </DataRow>
                                        <DataRow
                                            label={translatePanel('data_row_label.address')}
                                            qaIdent="service-case-vehicle-driver-address"
                                        >
                                            {serviceCase.vehicle?.driver?.address ? (
                                                <div>
                                                    <p>{serviceCase.vehicle.driver.address.street}</p>
                                                    <p>{`${serviceCase.vehicle.driver.address.postCode} ${serviceCase.vehicle.driver.address.city}`}</p>
                                                </div>
                                            ) : '-'}
                                        </DataRow>
                                    </div>
                                </PopOver>
                            </DropDownTrigger>
                        </DataRow>
                        <DataRow
                            label={translatePanel('data_row_label.owner')}
                            qaIdent="service-case-vehicle-owner"
                        >
                            {serviceCase.vehicle?.owner?.isIdenticalToMember
                                ? translatePanel('data_row_content.member')
                                : serviceCase.vehicle?.owner?.memberRelationship
                                    ? translate(`global.member_relationship_type.${serviceCase.vehicle.owner.memberRelationship.toLowerCase()}`)
                                    : '-'}
                            <DropDownTrigger>
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-full-width',
                                        'global!ace-u-flex--justify-flex-end',
                                        'global!ace-u-margin--right-32',
                                    ])}
                                >
                                    <Icon
                                        icon={detailsIcon}
                                        className={cx('global!ace-u-margin--top-4')}
                                    />
                                </div>
                                <PopOver alignment="end" hasBorder={true}>
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--direction-column',
                                            'global!ace-u-grid-column--span-4',
                                        ])}
                                    >
                                        <DataRow
                                            label={translatePanel('data_row_label.relationship_to_member')}
                                            qaIdent="service-case-vehicle-owner-member-relation"
                                        >
                                            {serviceCase.vehicle?.owner?.isIdenticalToMember
                                                ? translatePanel('data_row_content.member')
                                                : serviceCase.vehicle?.owner?.memberRelationship
                                                    ? translate(`global.member_relationship_type.${serviceCase.vehicle.owner.memberRelationship.toLowerCase()}`)
                                                    : '-'}
                                        </DataRow>
                                        <DataRow
                                            label={translatePanel('data_row_label.name')}
                                            qaIdent="service-case-vehicle-owner-name"
                                        >
                                            {serviceCase.vehicle?.owner?.name || '-'}
                                        </DataRow>
                                        <DataRow
                                            label={translatePanel('data_row_label.address')}
                                            qaIdent="service-case-vehicle-owner-address"
                                        >
                                            {serviceCase.vehicle?.owner?.address ? (
                                                <div>
                                                    <p>{serviceCase.vehicle.owner.address.street}</p>
                                                    <p>{`${serviceCase.vehicle.owner.address.postCode} ${serviceCase.vehicle.owner.address.city}`}</p>
                                                </div>
                                            ) : '-'}
                                        </DataRow>
                                    </div>
                                </PopOver>
                            </DropDownTrigger>
                        </DataRow>
                        <DataRow
                            label={translatePanel('data_row_label.trailer')}
                            qaIdent="service-case-trailer"
                        >
                            {serviceCase.trailer
                                ? translatePanel('data_row_content.yes')
                                : translatePanel('data_row_content.no')}
                            {serviceCase.trailer && (
                                <DropDownTrigger>
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-full-width',
                                            'global!ace-u-flex--justify-flex-end',
                                            'global!ace-u-margin--right-32',
                                        ])}
                                    >
                                        <Icon
                                            icon={detailsIcon}
                                            className={cx('global!ace-u-margin--top-4')}
                                        />
                                    </div>
                                    <PopOver alignment="end" hasBorder={true}>
                                        <div
                                            className={cx([
                                                'global!ace-u-flex--direction-column',
                                                'global!ace-u-grid-column--span-4',
                                            ])}
                                        >
                                            <DataRow
                                                label={translatePanel('data_row_label.license_plate')}
                                                qaIdent="service-case-trailer-license-plate"
                                            >
                                                {serviceCase.trailer?.licensePlateNumber || '-'}
                                            </DataRow>
                                            <DataRow
                                                label={translatePanel('data_row_label.trailer_type')}
                                                qaIdent="service-case-trailer-type"
                                            >
                                                {serviceCase.trailer?.type
                                                    ? translate(`global.trailer_type.${serviceCase.trailer.type.toLowerCase()}`)
                                                    : '-'}
                                            </DataRow>
                                            <DataRow
                                                label={translatePanel('data_row_label.manufacturer')}
                                                qaIdent="service-case-trailer-manufacturer"
                                            >
                                                {serviceCase.trailer?.manufacturer || '-'}
                                            </DataRow>
                                            <DataRow
                                                label={translatePanel('data_row_label.trailer_model')}
                                                qaIdent="service-case-trailer-model"
                                            >
                                                {serviceCase.trailer?.model || '-'}
                                            </DataRow>
                                            <DataRow
                                                label={translatePanel('data_row_label.weight')}
                                                qaIdent="service-case-trailer-weight"
                                            >
                                                {serviceCase.trailer?.weight || '-'}
                                            </DataRow>
                                            <DataRow
                                                label={translatePanel('data_row_label.other')}
                                                qaIdent="service-case-trailer-notes"
                                            >
                                                {serviceCase.trailer?.notes || '-'}
                                            </DataRow>
                                        </div>
                                    </PopOver>
                                </DropDownTrigger>
                            )}
                        </DataRow>
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--direction-column',
                            'global!ace-u-grid-column--span-4',
                        ])}
                    >
                        <p
                            className={cx([
                                'global!ace-u-margin--bottom-24',
                                'global!ace-u-typography--variant-h3',
                            ])}
                        >
                            {translatePanel('section_title.other')}
                        </p>
                        <DataRow
                            label={translatePanel('data_row_label.third_party_accident')}
                            qaIdent="invoice-is-accident"
                        >
                            {typeof invoice.isAccident === 'boolean'
                                ? invoice.isAccident
                                    ? translatePanel('data_row_content.yes')
                                    : translatePanel('data_row_content.no')
                                : '-'}
                        </DataRow>
                        <DataRow
                            label={translatePanel('data_row_label.persons_on_site')}
                            qaIdent="service-case-persons-at-site"
                        >
                            {serviceCase.adultsAtSite || serviceCase.childrenAtSite
                                ? translatePanel('data_row_content.persons_on_site', {adultsNumber: serviceCase.adultsAtSite, childrenNumber: serviceCase.childrenAtSite})
                                : '-'}
                        </DataRow>
                        <DataRow
                            label={translatePanel('data_row_label.main_service_note')}
                            qaIdent="invoice-main-services-comment"
                        >
                            {invoice.mainServicesComment || '-'}
                        </DataRow>
                        <DataRow
                            label={translatePanel('data_row_label.additional_service_note')}
                            qaIdent="invoice-additional-services-comment"
                        >
                            {invoice.additionalServicesComment || '-'}
                        </DataRow>
                    </div>
                </div>
            )}
        </Panel>
    );
};

InvoiceOverviewBaseDataPanel.propTypes = {
    isAdditionalInfoExpanded: PropTypes.bool.isRequired,
    setIsAdditionalInfoExpanded: PropTypes.func.isRequired,
    invoice: PropTypes.object,
    members: PropTypes.object,
    membersByMembershipNo: PropTypes.object,
    serviceCase: PropTypes.object,
};

InvoiceOverviewBaseDataPanel.defaultProps = {
    invoice: null,
    members: null,
    membersByMembershipNo: null,
    serviceCase: null,
};

const mapStateToProps = (state, props) => {
    const getInvoice = invoiceSelectors.createInvoicesSelector();
    const getServiceCase = serviceCaseSelectors.createServiceCaseSelector();
    return {
        invoice: getInvoice(state, props),
        serviceCase: getServiceCase(state, props),
        members: state.members.members,
        membersByMembershipNo: state.members.membersByMembershipNo,
    };
};

export default withDropDownProvider(withRouter(connect(mapStateToProps)(InvoiceOverviewBaseDataPanel)));
