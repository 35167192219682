import {put} from 'redux-saga/effects';
import config from '../../config';
import * as invoiceActionTypes from '../invoiceActionTypes';

const loadInvoiceSearchResults = function* loadInvoiceSearchResults(payload) {
    if (!payload) return;
    const {location} = payload.payload;
    const searchQueryParams = new URLSearchParams(location.search);
    searchQueryParams.append('size', `${config.DEFAULT_PAGE_SIZE}`);
    searchQueryParams.append('sort', 'createdAt,desc');

    yield put({
        type: invoiceActionTypes.SEARCH_INVOICES,
        payload: {searchQueryParams},
    });
    window.sessionStorage.setItem('serviceCaseOriginRoute', location.pathname);
};

export default loadInvoiceSearchResults;
