import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Badge, closeIcon, Icon, memberIcon, ScreenTitle, useStyles} from '@ace-de/ui-components';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter} from '@computerrock/formation-router';
import {snakeCase} from 'change-case';
import {ampMemberStatusTypes, ampMemberTariffGroups} from '@ace-de/eua-entity-types';
import goToParentRoute from '../../utils/goToParentRoute';

const MemberHeader = ({member, history, match}) => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateHeader = createTranslateShorthand('member_header');
    const {personalDetails} = member;
    const {address} = personalDetails;
    const memberAddress = [address.street, `${address.postCode}`, `${address.city}`, address.country]
        .filter(value => !!value).join(', ');

    return (
        <Fragment>
            <ScreenTitle>
                <div className={cx('global!ace-u-flex', 'global!ace-u-flex--align-center')}>
                    <Icon
                        icon={memberIcon}
                        className={cx([
                            'ace-c-icon--color-contrast',
                            'global!ace-u-margin--right-8',
                        ])}
                    />
                    <div
                        className={cx([
                            'global!ace-u-margin--right-8',
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-typography--color-contrast',
                        ])}
                        data-qa="member-header-name"
                    >
                        {personalDetails.displayName}
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-typography--variant-body',
                            'global!ace-u-typography--color-contrast',
                        ])}
                        data-qa="member-header-membership-number"
                    >
                        {member.membershipNo}
                    </div>
                </div>
            </ScreenTitle>
            <div
                className={cx([
                    'global!ace-u-flex--grow-1',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-space-between',
                    'global!ace-u-margin--left-32',
                ])}
            >
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                    ])}
                >
                    <div
                        className={cx([
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-margin--left-24',
                            'global!ace-u-flex',
                        ])}
                        data-qa="member-header-tariff"
                    >
                        {translateHeader('label.tariff')}:&nbsp;
                        <span
                            className={cx('ace-u-typography--variant-body')}
                        >
                            {member.tariffDetails.tariffGroup
                                ? translate(`global.member_tariff_group.${snakeCase(ampMemberTariffGroups[member.tariffDetails.tariffGroup])}`)
                                : '-'}
                        </span>
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-typography--variant-body-medium',
                            'global!ace-u-margin--left-32',
                            'global!ace-u-flex',
                        ])}
                        data-qa="member-header-status"
                    >
                        {translateHeader('label.status')}:&nbsp;
                        {member.status !== ampMemberStatusTypes.EMPTY
                            ? (
                                <Badge status={member.status.toLowerCase()}>
                                    {translate(`global.member_status.${snakeCase(ampMemberStatusTypes[member.status])}`)}
                                </Badge>
                            ) : '-'}
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-typography--variant-body-medium',
                            'global!ace-u-margin--left-32',
                            'global!ace-u-flex',
                        ])}
                        data-qa="member-header-address"
                    >
                        {translateHeader('label.address')}:&nbsp;
                        <span
                            className={cx('ace-u-typography--variant-body')}
                        >
                            {memberAddress || '-'}
                        </span>
                    </div>
                </div>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-flex-end',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-margin--right-32',
                    ])}
                    data-qa="member-header-close-button"
                >
                    <Icon
                        icon={closeIcon}
                        onClick={() => goToParentRoute({
                            currentPath: match.path,
                            history,
                        })}
                    />
                </div>
            </div>
        </Fragment>
    );
};

MemberHeader.propTypes = {
    member: PropTypes.object,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

MemberHeader.defaultProps = {
    member: null,
};

export default withRouter(MemberHeader);
