import {resolveRoute} from '@computerrock/formation-router';
import routePaths from '../routePaths';

const goToParentRoute = ({currentPath, history, pathParams = {}, toParams = {}}) => {
    let path;

    switch (currentPath) {
        case routePaths.INVOICES:
        case routePaths.INVOICE_OVERVIEW: {
            path = routePaths.SERVICE_CASES_OVERVIEW;
            break;
        }

        case routePaths.SERVICE_CASES_OVERVIEW:
        case routePaths.SERVICE_CASE: {
            path = window.sessionStorage.getItem('serviceCaseOriginRoute') || routePaths.DASHBOARD;
            break;
        }

        case routePaths.MEMBER: {
            path = routePaths.DASHBOARD;
            break;
        }

        default:
            // no-op
    }

    if (!path) history.goBack();

    history.push(resolveRoute(path, pathParams, toParams));
};

export default goToParentRoute;
