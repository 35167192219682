import {produce, current} from 'immer';
import {LeistServiceCase, LeistLocation, efLocationTypes, LeistAttachment, ACEPartner} from '@ace-de/eua-entity-types';
import * as serviceCaseActionTypes from './serviceCaseActionTypes';

const initialState = {
    serviceCases: {},
    serviceCasesTotalCount: 0,
    serviceCaseSearchResults: [],
    serviceCaseDocuments: [],
    vehicleInfos: {},
    accountPartyOptionsIds: [],
    commissioners: {},
};

/**
 * Service cases reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */
const serviceCaseReducer = produce((draft, action) => {
    switch (action.type) {
        case serviceCaseActionTypes.STORE_SERVICE_CASES: {
            const {serviceCaseDTOs, totalCount} = action.payload;
            serviceCaseDTOs.forEach(serviceCaseDTO => {
                const serviceCase = draft.serviceCases[serviceCaseDTO.id];
                if (!serviceCase) {
                    const newServiceCase = new LeistServiceCase().fromDTO(serviceCaseDTO);
                    draft.serviceCases[newServiceCase.id] = newServiceCase;
                    return;
                }
                draft.serviceCases[serviceCaseDTO.id] = serviceCase.fromDTO(serviceCaseDTO);
            });
            draft.serviceCasesTotalCount = totalCount;
            break;
        }

        case serviceCaseActionTypes.SET_SERVICE_CASE_DAMAGE_LOCATION_CANDIDATES: {
            const {arcGISLocationCandidateDTOs, serviceCaseId, searchQueryString} = action.payload;
            const serviceCase = draft.serviceCases[serviceCaseId];
            serviceCase[`damageLocationSearchQuery`] = searchQueryString;
            serviceCase[`damageLocationCandidates`] = arcGISLocationCandidateDTOs
                .map(arcGISLocationCandidateDTO => {
                    const locationCandidate = new LeistLocation().fromDTO(arcGISLocationCandidateDTO);
                    return locationCandidate.setLocationType(efLocationTypes.SELECT_LOCATION);
                });
            break;
        }
        case serviceCaseActionTypes.SET_SERVICE_CASE_PERSISTENCE_STATE: {
            const {serviceCaseId, persistenceState} = action.payload;
            const serviceCase = draft.serviceCases[serviceCaseId];
            if (serviceCase) {
                draft.serviceCases[serviceCaseId] = serviceCase.setPersistenceState(persistenceState);
            }
            break;
        }

        case serviceCaseActionTypes.SET_INVOICES: {
            const {serviceCaseId, invoiceDTOs} = action.payload;
            let serviceCase = current(draft.serviceCases[serviceCaseId]);

            invoiceDTOs.forEach(invoiceDTO => {
                serviceCase = serviceCase.addInvoiceId(invoiceDTO.id);
            });
            draft.serviceCases[serviceCaseId] = serviceCase;
            break;
        }

        case serviceCaseActionTypes.STORE_SERVICE_CASE_SEARCH_RESULTS: {
            const {serviceCaseDTOs, totalCount} = action.payload;
            draft.serviceCaseSearchResults = serviceCaseDTOs.map(serviceCaseDTO => {
                return new LeistServiceCase().fromDTO(serviceCaseDTO);
            });
            draft.serviceCasesTotalCount = typeof totalCount === 'number' ? totalCount : 0;
            break;
        }

        case serviceCaseActionTypes.STORE_SERVICE_CASE_DOCUMENTS: {
            const {serviceCaseDocumentsDTOs} = action.payload;
            draft.serviceCaseDocuments = serviceCaseDocumentsDTOs.data.map(serviceCaseDocumentDTO => (
                new LeistAttachment().fromDTO(serviceCaseDocumentDTO)
            ));
            break;
        }

        case serviceCaseActionTypes.STORE_VEHICLE_MANUFACTURERS: {
            const {vehicleManufacturers, vehicleType} = action.payload;
            // since DAT IDs are integers, starting from 1, we have to change the format
            // in our system, so an agent can filter suggestions properly
            // linked issue: https://computerrock.atlassian.net/browse/ACEECS-3400
            const manufacturersMap = {};
            for (const [manufacturerId, manufacturerData] of Object.entries(vehicleManufacturers)) {
                manufacturersMap[`DAT-${manufacturerId}`] = manufacturerData;
            }
            draft.vehicleInfos[vehicleType] = manufacturersMap;
            break;
        }

        case serviceCaseActionTypes.STORE_VEHICLE_MODELS: {
            const {manufacturer, vehicleType, vehicleModels} = action.payload;
            // since DAT IDs are integers, starting from 1, we have to change the format
            // in our system, so an agent can filter suggestions properly
            // linked issue: https://computerrock.atlassian.net/browse/ACEECS-3400
            const modelsMap = {};
            for (const [modelId, modelData] of Object.entries(vehicleModels)) {
                modelsMap[`DAT-${modelId}`] = modelData;
            }
            draft.vehicleInfos[vehicleType][`DAT-${manufacturer}`].models = modelsMap;
            break;
        }

        case serviceCaseActionTypes.STORE_VEHICLE_VARIANTS: {
            const {manufacturer, vehicleType, vehicleVariants, baseModel} = action.payload;
            // since DAT IDs are integers, starting from 1, we have to change the format
            // in our system, so an agent can filter suggestions properly
            // linked issue: https://computerrock.atlassian.net/browse/ACEECS-3400
            const variantsMap = {};
            for (const [variantId, variantData] of Object.entries(vehicleVariants)) {
                variantsMap[`DAT-${variantId}`] = variantData;
            }
            draft.vehicleInfos[vehicleType][`DAT-${manufacturer}`].models[`DAT-${baseModel}`].variants = variantsMap;
            break;
        }

        case serviceCaseActionTypes.STORE_ACCOUNT_PARTY_OPTIONS_IDS: {
            const {accountPartyOptionsIds} = action.payload;
            draft.accountPartyOptionsIds = accountPartyOptionsIds;
            break;
        }

        case serviceCaseActionTypes.STORE_ACE_COMMISSIONERS: {
            const {commissionerDTOs} = action.payload;
            commissionerDTOs.forEach(commissionerDTO => {
                const commissioner = draft.commissioners[commissionerDTO.id];
                if (!commissioner) {
                    const newCommissioner = new ACEPartner().fromDTO(commissionerDTO);
                    draft.commissioners[newCommissioner.id] = newCommissioner;
                    return;
                }
                draft.commissioners[commissionerDTO.id] = commissioner.fromDTO(commissionerDTO);
            });
            break;
        }

        default:
        //  no-op
    }
    return draft;
}, initialState);

export default serviceCaseReducer;
