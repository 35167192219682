import {fork, put, select, take} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as memberActionTypes from '../memberActionTypes';

const loadMemberDetails = function* loadMemberDetails({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const membersService = serviceManager.loadService('membersService');

    const {match, location} = payload;
    const {membershipNo} = match.params;
    yield fork(fetchRequest, memberActionTypes.FETCH_MEMBER_REQUEST, membersService.getMember, {
        membershipNo,
    });

    const responseAction = yield take([
        memberActionTypes.FETCH_MEMBER_REQUEST_SUCCEEDED,
        memberActionTypes.FETCH_MEMBER_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {memberDTO} = response;

        yield put({
            type: memberActionTypes.STORE_MEMBER,
            payload: {memberDTO},
        });
    }
    window.sessionStorage.setItem('serviceCaseOriginRoute', location.pathname);
};

export default loadMemberDetails;
