import {select, fork, take, put} from 'redux-saga/effects';
import {apmACEPartnerTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';

const loadACECommissioners = function* loadACECommissioners() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    yield fork(fetchRequest,
        serviceCaseActionTypes.FETCH_ACE_COMMISSIONERS_REQUEST,
        partnerManagementService.getACEPartnersV2,
        {partnerType: apmACEPartnerTypes.COMMISSIONER});

    const responseAction = yield take([
        serviceCaseActionTypes.FETCH_ACE_COMMISSIONERS_REQUEST_SUCCEEDED,
        serviceCaseActionTypes.FETCH_ACE_COMMISSIONERS_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {acePartnerDTOs} = response;

        yield put({
            type: serviceCaseActionTypes.STORE_ACE_COMMISSIONERS,
            payload: {commissionerDTOs: acePartnerDTOs},
        });
    }
};

export default loadACECommissioners;
